<template>
  <div class="about" style="padding-top: 20px;">
    <div class="mt40">
      <h1 style="text-align: center; margin-bottom: 20px;">Our Journey</h1>
    </div>
    <div class="mb80 timeline"></div>
    <div
      class="extended mt0 flex wrap founders_parent_about"
      style="margin-bottom: 40px;"
    >
      <div
        v-if="$mq == 'mobile'"
        style="height: 300px;"
        class="section right founders"
      ></div>
      <div class="section left">
        <h1 class="bold mt64 fw600">From the Founder's Desk</h1>
        <p class="mt16" v-html="$bus.siteData.fromTheFoundersDesk"></p>
        <button
          class="mt40 black"
          @click="
            $bus.redirect(
              'https://www.linkedin.com/in/gita-balakrishnan-161aa814/?originalSubdomain=in', false, true
            )
          "
        >
          Connect on Linkedin
        </button>
      </div>
      <div v-if="$mq != 'mobile'" class="section right founders"></div>
    </div>
    <div style="padding: 40px 100px; background: #eee;">
      <h4 style="text-align: center; margin-bottom: 20px;">Team Ethos</h4>
      <p style="text-align: center;">{{ $bus.siteData.teamDescription }}</p>
      <div class="team extended">
        <div v-for="t in $bus.siteData.team" :key="t.name" class="team_member">
          <div
            class="photo"
            v-if="t.profilePicture"
            :style="{
              background: 'url(' + t.profilePicture.url + ') center / cover'
            }"
          ></div>
          <h6 class="name bold">{{ t.name }}</h6>
          <p class="designation">{{ t.designation }}</p>
          <a :href="t.linkedInUrl !== null ? t.linkedInUrl : '#'" target="_blank">
            <i
              v-if="t.linkedInUrl"
              class="linkedin icon"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
export default {
  name: "about",
  metaInfo() {
    return {
      title: "Architecture, Construction, Engineering And Design e-learning Porta",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content:
            "India’s largest portal on Architecture, Construction, Engineering & Design (ACED) for competitions, courses, connections, collaborations & supporting causes."
        },
        {
          hid: "keywords",
          name: "keywords",
          content:
            ""
        }
      ]
    }
  },
  mounted () {
    $(document).scrollTop(0);
  }
};
</script>

<style>
.extended {
  width: calc(100% + 200px);
  margin-left: -100px;
}

@media (max-width: 800px) {
  .extended {
    width: calc(100% + 120px);
    margin-left: -60px;
  }
}

.founders {
  background: url("../assets/founders_desk.svg");
  background-size: contain !important;
  background-position: center;
  background-repeat: no-repeat;
}

.founders_parent_about {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 60px 140px;
}

@media (max-width: 600px) {
  .knowledge_is_power,
  .founders_parent_about {
    padding: 100px;
  }
}

.ethos_of_learning {
  background: url("../assets/ethos_of_learning.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.timeline {
  background: url("../assets/timeline.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 300vh;
}
@media (max-width: 800px) {
  .timeline {
    background-size: cover;
    height: 170vh;
  }
}

.team {
  background-size: contain, contain;
  background-position: top, bottom;
  background-repeat: no-repeat, no-repeat;
  padding-bottom: 80px;
}

video {
  width: 80%;
  height: 400px;
}
.hero {
  display: flex;
  width: 100%;
}
.section.left {
  width: 50%;
  position: relative;
}
.section.right {
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding: 20px;
  width: 50%;
}

.mobile-center {
  text-align: initial;
}

@media (max-width: 800px) {
  .section.left {
    width: 100%;
    position: relative;
  }
  .section.right {
    width: 100%;
    position: relative;
  }
  .mobile-center {
    text-align: center;
  }
}

.home .section.right h1,
.home .section.right p {
  margin: 8px;
}
.home .image {
  position: absolute;
  bottom: 0px;
  left: -8px;
}
.home .image:first-child {
  width: 400px;
  height: 400px;
  background: #ccc;
}
.home .image:nth-child(2) {
  width: 250px;
  height: 250px;
  background: #ddd;
  left: 267px;
}
.home .image:nth-child(3) {
  width: 160px;
  height: 160px;
  background: #eee;
  left: 437px;
}

.team {
  display: flex;
  flex-wrap: wrap;
  padding-top: 24px;
  top: 50px;
  z-index: -1;
}

.team_member {
  width: 20%;
  margin: 20px 0px;
}

@media (max-width: 1500px) {
  .team_member {
    width: 25%;
  }
}

@media (max-width: 1000px) {
  .team_member {
    width: 33%;
  }
}

@media (max-width: 600px) {
  .team_member {
    width: 50%;
  }
}

.team_member .photo {
  width: 110px;
  height: 110px;
  border-radius: 100px;
  background: #eee;
  margin: 4px auto;
}

.team_member .name,
.team_member .designation {
  text-align: center;
}
.team_member .name {
  color: #333;
  margin-top: 8px;
  font-size: 16px;
  letter-spacing: 0em;
  font-weight: bold;
  text-transform: none;
}

.team_member .designation {
  font-size: 16px;
  color: #999;
}

.team_member i {
  width: 100%;
  color: #865a18;
  font-size: 21px;
  margin-top: 4px;
}
.whatsapp_logo {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 80px;
  height: 80px;
  cursor: pointer;
  display: flex;
  background: url("../assets/whatsapp_logo.png");
  background-size: contain !important;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
